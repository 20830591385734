const env =
  process.env.REACT_APP_ENV || process.env.ENVIRONMENT || process.env.NODE_ENV;
const isProd =
  env && (env.toLowerCase() === "prod" || env.toLowerCase() === "production");

let apiUrl = "http://localhost:9000";
let instance = "local";
let magiclinkKey = "pk_live_2C36B5F09225CD74";

if (true) {
  apiUrl = "https://api.zolo.com.au";
  instance = "prod";
  magiclinkKey = "pk_live_5AFD47660BB1950C";
}

const config = {
  apiUrl,
  instance,
  magiclinkKey,
};

export default config;
