import {
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const AccountHeader: React.FC<any> = (props: any) => {
  const { title, rightContent, ...rest } = props;

  return (
    <Flex
      position="absolute"
      left="0"
      top="0"
      h="60px"
      w="100%"
      bg="white"
      zIndex={5}
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      px="16px"
      borderBottom="1px solid #E2E8F0"
      {...rest} // Spread the rest of the props here
    >
      <Box fontSize="20px" fontWeight="600">
        {title}
      </Box>
      <Box>
        {rightContent}
      </Box>
    </Flex>
  );
};

export default AccountHeader;
