import { useContext, useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Image,
  useDisclosure,
  SlideFade,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { JobService } from "../../service/job/job.service";
import { convertIsoTime, getDayPeriod } from "../../util/date.util";
import { PageTitle } from "../../components/shared/PageTitle";
import { ToggleInput } from "../../components/form/controls/toggle.input";
import { useSelector } from "react-redux";
import { JobProgress } from "../../components/app/dashboard/JobProgress";
import { CompanyService } from "../../service/company/company.service";
import { DashboardModel } from "../../models/dashboard.model";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { ForestBackground } from "../../components/shared/ForestBackground";
import { DropoffService } from "../../service/company/dropoff.service";
import { AppContext } from "../../App";

const jobService = new JobService();
const companyService = new CompanyService();
const dropoffService = new DropoffService();
export const Dashboard = ({ }) => {
  const { displayName } = useSelector((state: any) => state.userProfile);

  const [dropoffs, setDropoffs] = useState([] as any[]);

  const [loading, setLoading] = useState(false);
  const [toggled, setToggled] = useState(false);

  const [jobs, setJobs] = useState([] as any[]);
  const [completeJobs, setCompleteJobs] = useState([] as any[]);

  const init = async () => {
    setLoading(true);
    await jobService
      .getList()
      .then((jobs) => {
        const data = jobs.map((job) => {
          return {
            ...job,
            startDate: convertIsoTime(job.startDate),
          };
        });

        setCompleteJobs(data.filter((x) => x.status.toString() === "COMPLETE"));
        setJobs(data.filter((x) => x.status.toString() !== "COMPLETE"));
      })
      .finally(() => {
        setLoading(false);
      });

    await dropoffService.getList().then((dropoffs) => {
      const activeDropoffs = dropoffs.filter(
        (dropoff) => dropoff.status.toString() !== "PROCESSED"
      );
      setDropoffs(activeDropoffs);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const { isOpen, onOpen } = useDisclosure();

  useEffect(() => {
    const timer = setTimeout(() => {
      onOpen();
    }, 500);

    return () => clearTimeout(timer);
  }, [onOpen]);

  return (
    <>
      <ForestBackground />
      <SlideFade in={isOpen} offsetY="-20px">
        <Center>
          <Box
            zIndex={1}
            textAlign="center"
            color={getDayPeriod() === "evening" ? "white" : "brand.green.300"}
            fontWeight="bold"
            fontSize={{ base: "36px", md: "42px" }}
            fontFamily="zolo-header"
            mb="52px"
          >
            {getDayPeriod() === "evening" ? "✨" : "☀️"} Good {getDayPeriod()},{" "}
            {displayName}.
          </Box>
        </Center>
      </SlideFade>
      <Flex zIndex={1} flexDir="row" alignSelf="center" maxW="950px" w="100%">
        <Box w="100%">
          <CardsSection />
          <ProgressSection
            jobs={jobs}
            dropoffs={dropoffs}
            completeJobs={completeJobs}
            onToggle={(e) => setToggled(e.target.checked)}
            toggled={toggled}
          />
        </Box>
      </Flex>
    </>
  );
};

const CardsSection = () => {
  const [dashboard, setDashboard] = useState({} as DashboardModel);
  const { currencySymbol } = useContext(AppContext);

  const { rebateRequired } = useSelector(
    (state: any) => state.company
  );

  // Individual state values for each card's visibility
  const [showWeightCard, setShowWeightCard] = useState(false);
  const [showEmissionCard, setShowEmissionCard] = useState(false);
  const [showCountCard, setShowCountCard] = useState(false);
  const [showCreditsCard, setShowCreditsCard] = useState(false);

  const init = async () => {
    const dashboard = await companyService.getDashboard();
    setDashboard(dashboard);

    // Staggered timeouts for each card's animation
    setTimeout(() => setShowWeightCard(true), 1000);
    setTimeout(() => setShowEmissionCard(true), 1200);
    setTimeout(() => setShowCountCard(true), 1400);
    setTimeout(() => setShowCreditsCard(true), 1600);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex
        flexDir="row"
        justifyContent="center"
        alignSelf="center"
        p="16px"
        py="48px"
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
          wrap="wrap"
          gap={{ base: "36px", md: undefined }}
        >
          <SlideFade in={showWeightCard} offsetY="-20px">
            <DashboardCard
              type="weight"
              value={`${dashboard.totalWeight ?? 0}kg`}
              text="e-waste diverted from landfill"
            />
          </SlideFade>
          <SlideFade in={showEmissionCard} offsetY="-20px">
            <DashboardCard
              type="emission"
              value={`${(+(dashboard.totalEmission ?? 0)).toLocaleString(
                "en-US"
              )}kg`}
              text={
                <Box>
                  <Text>total estimated</Text> C02 avoidance
                </Box>
              }
            />
          </SlideFade>
          <SlideFade in={showCountCard} offsetY="-20px">
            <DashboardCard
              type="count"
              value={`${dashboard.totalInventory ?? 0}`}
              text={
                <Box>
                  <Text>total items</Text> recovered
                </Box>
              }
            />
          </SlideFade>
          {rebateRequired && (
            <SlideFade in={showCreditsCard} offsetY="-20px">
              <DashboardCard
                type="credits"
                value={`${currencySymbol}${(
                  dashboard.credits ?? 0
                ).toLocaleString("en-US")}`}
                text={
                  <Box>
                    <Text fontSize="14px">total credits unlocked</Text> (buyback
                    credits)
                  </Box>
                }
              />
            </SlideFade>
          )}
        </Flex>
      </Flex>
      {/* <Flex flexDir="column" alignItems="center">
        <SlideFade in={showCreditsCard} offsetY="-20px">
          <DashboardCard
            w="400px"
            pt="60px"
            type="pickup"
            value={dashboard.totalPickupRequests ?? 0}
            text={
              <Box>
                <Text>pick-ups requested</Text>
              </Box>
            }
            iconStyle={{ left: "32%" }}
          />
        </SlideFade>
      </Flex> */}
    </>
  );
};

const ProgressSection = ({
  jobs,
  completeJobs,
  dropoffs,
  toggled,
  onToggle,
}) => {
  const [showLabel, setShowLabel] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [defaultDropoffs, setDefaultDropoffs] = useState([] as any[]);

  const init = async () => {
    setTimeout(() => setShowLabel(true), 2000);
    setTimeout(() => setShowProgress(true), 2400);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Flex my="16px" flexDirection="column">
      <SlideFade in={showLabel} offsetY="-20px">
        <Flex
          my="13px"
          mb="26px"
          flexDirection={{ base: "column", md: "row" }}
          gap="16px"
          justifyContent="space-between"
        >
          <PageTitle
            textTransform="none"
            fontSize="26px"
            color={getDayPeriod() === "evening" ? "white" : undefined}
          >
            Requested pick-ups
          </PageTitle>
          <ToggleInput
            style={{
              width: "fit-content",
              justifyContent: "flex-end",
              backgroundColor: "rgba(255, 255, 255, 0.2)", // adjust color and transparency here
              backdropFilter: "blur(10px)", // adjust blur radius here
              padding: "8px 16px",
              borderRadius: "8px",
            }}
            leftLabel="In progress"
            rightLabel="Complete"
            onChange={onToggle}
          />
        </Flex>
      </SlideFade>

      <SlideFade in={showProgress} offsetY="-40px">
        <Flex flexDirection="column" gap="6px" w="100%">
          {!!(toggled ? completeJobs : jobs)?.length ||
            (!toggled && !!dropoffs?.length) ? (
            <>
              {!toggled &&
                dropoffs?.map((dropoff, i) => (
                  <JobProgressPlaceholder
                    key={`dropoff-${i}`}
                    date={dropoff.requestDate}
                  />
                ))}

              {(toggled ? completeJobs : jobs).map((job, i) => (
                <JobProgress
                  defaultOpen={i === 0}
                  key={`job-${job.id}`}
                  job={job}
                />
              ))}
            </>
          ) : (
            <EmptyProgress />
          )}
        </Flex>
      </SlideFade>
    </Flex>
  );
};

const EmptyProgress = () => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      p="24px"
      gap="24px"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.2)", // adjust color and transparency here
        backdropFilter: "blur(10px)", // adjust blur radius here
      }}
      borderRadius="16px"
    >
      <Image src="/assets/dashboard/icons/recycle.svg" w="240px" h="240px" />
      <Box fontSize="24px" fontWeight="bold" color="brand.green.300">
        Turn your old tech into impact.
      </Box>
      <Box color="brand.green.300">
        Create a new pick-up request and track progress here.
      </Box>
      <ButtonCustom onClick={() => navigate("/app/dropoff")}>
        New pick-up request
      </ButtonCustom>
    </Flex>
  );
};

const DashboardCard = ({
  type,
  value,
  text,
  iconStyle = undefined,
  ...props
}) => {
  const styles = { fontSize: "56px" };

  const Icon = () => {
    switch (type) {
      case "weight":
        return "battery.svg";
      case "emission":
        return "footprint.svg";
      case "count":
        return "earth.svg";
      case "pickup":
        return "truck.svg";
      default:
        return "care.svg";
    }
  };

  return (
    <Flex
      boxShadow="0px 8px 16px 0px #00000033"
      pos="relative"
      flexDir="column"
      p="24px"
      pt="48px"
      bg="white"
      border="1px solid lightGray"
      textAlign="center"
      justifyContent="space-between"
      h="180px"
      w="200px"
      borderRadius="10px"
      {...props}
    >
      <Box pos="absolute" top="-50px" left="25%" style={iconStyle}>
        <Image src={`/assets/dashboard/icons/${Icon()}`} />
      </Box>
      <Box
        color="brand.green.200"
        fontFamily="zolo-header"
        fontSize={value?.length > 5 ? "28px" : "32px"}
        fontWeight="bold"
      >
        {value}
      </Box>
      <Box color="brand.gray.400">{text}</Box>
    </Flex>
  );
};

const JobProgressPlaceholder = ({ date }) => {
  const jobPlaceholder = {
    status: "PICKUP",
    totalInventory: "-",
    startDate: convertIsoTime(date),
    contactEmail: "-",
  };

  return <JobProgress job={jobPlaceholder} defaultOpen={false} />;
};
