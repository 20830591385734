import { Magic } from "magic-sdk";
import { BaseMagiclinkService } from "./base-magiclink.service";

export class MagiclinkService extends BaseMagiclinkService {
  constructor() {
    super();
  }

  logout = async () => {
    await this.magic!.user.logout();
  };

  handleLoginWithEmail = async (email: string, isLogin: boolean = true) => {
    await this.magic!.auth.loginWithMagicLink({
      email,
      showUI: true,
      redirectURI: new URL(
        `/app/auth/${isLogin ? "login" : "register"}/redirect`,
        window.location.origin
      ).href,
    }).addListener("done", (token: string | null) => {
      return token;
    });
  };

  handleLoginWithSocial = async (
    provider: "google" | "microsoft",
    isLogin: boolean = true
  ) => {
    await this.magicSocial!.oauth.loginWithRedirect({
      provider,
      redirectURI: new URL(
        `/app/auth/${
          isLogin ? "login" : "register"
        }/redirect?social_login=true`,
        window.location.origin
      ).href,
    });
  };

  handleUserRegisterWithEmail = async (email: string, regToken: string) => {
    await this.magic!.auth.loginWithMagicLink({
      email,
      showUI: true,
      redirectURI: new URL(
        `/app/auth/user/register/${regToken}`,
        window.location.origin
      ).href,
    }).addListener("done", (token: string | null) => {
      return token;
    });
  };

  handleUserRegisterWithSocial = async (
    provider: "google" | "microsoft",
    regToken: string
  ) => {
    await this.magicSocial!.oauth.loginWithRedirect({
      provider,
      redirectURI: new URL(
        `/app/auth/user/register/${regToken}?social_login=true`,
        window.location.origin
      ).href,
    });
  };

  getUser = async () => {
    return this.magic?.user?.getMetadata();
  };

  // Do not use unless you have to
  // Use Redux !!token.accessToken instead
  isAuthenticated = async () => {
    return new Promise<boolean>((resolve) => {
      this.magic?.user?.isLoggedIn().then((loggedIn) => {
        resolve(loggedIn);
      });
    });
  };

  getIdToken = async () => {
    return new Promise<string>((resolve) => {
      this.magic?.user?.getIdToken().then((token) => {
        resolve(token);
      });
    });
  };

  userEmail = async () => {
    return new Promise<string | null>((resolve) => {
      this.magic?.user
        ?.getMetadata()
        .then((metaData) => {
          resolve(metaData?.email);
        })
        .catch(async () => {
          await this.logout();
          resolve("");
        });
    });
  };
}

export const magiclinkService = new MagiclinkService();