import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";

const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <ChakraTooltip
      placement="right"
      ml="5px"
      p="10px"
      borderRadius="6px"
      bg="brand.green.100"
      color="white"
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;