import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  Center,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InboxIcon from "@mui/icons-material/Inbox";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import SecurityIcon from "@mui/icons-material/Security";
import { AvatarCustom } from "../form/avatar.custom";
import { Loading } from "../shared/Loading";
import { ReactComponent as CompostIconSvg } from "../../custom-assets/compost.svg";
import {
  magiclinkService,
} from "../../service/magiclink/magiclink.service";
import { RootState, destroyAllState } from "../../store";
import { ellipsesText } from "../../util/string.util";
import { Icons } from "../shared/Icons";
import Tooltip from "../shared/Tooltip";
import { toggleSidebar as toggleSidebarAction } from "../../store/slice/Account/user-profile.slice";

const links = [
  { route: "dashboard", name: "Dashboard", icon: DashboardIcon },
  { route: "dropoff", name: "Pick-up Request", icon: InboxIcon },
  { route: "job", name: "GreenGuard™ Tracker", icon: SecurityIcon },
  { route: "branch", name: "Branches", icon: BusinessOutlinedIcon },
];

export const Sidebar: React.FC<any> = (props: any) => {
  const { onToggle, style } = props;

  const { photoUri } = useSelector((state: RootState) => state.userProfile);
  const { sidebarCollapsed } = useSelector((state: RootState) => state.userProfile);

  const {
    name: companyName,
    isHeadquarters,
    rebateRequired,
  } = useSelector((state: RootState) => state.company);

  const { credits, monthEmission } = useSelector(
    (state: RootState) => state.dashboard
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState(sidebarCollapsed ? 80 : 240);
  const [sidebarOpen, setSidebarOpen] = useState(!sidebarCollapsed);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setSidebarWidth(sidebarOpen ? 80 : 240);
    if (onToggle) onToggle(!sidebarOpen);
    dispatch(toggleSidebarAction());
  };

  const navigate = useNavigate();

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/");
        setLoading(false);
      });
  };

  return (
    <Box
      w={`${sidebarWidth}px`}
      transition="width 0.3s ease-in-out"
      minH="100%"
      position="fixed"
      zIndex={11}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Loading loading={loading} fill ignoreDelay />
      <Flex
        minH="100vh"
        bg="brand.green.200"
        borderRadius="8px"
        border="3px solid white"
        transition="width 0.2s ease-in-out"
        py="12px"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
        px="4px"
        pb={{ base: "100px", md: 0 }}
        {...style}
      >
        <Box>
          <Flex flexDir="column" gap="6px" alignItems="center">
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent={sidebarOpen ? "space-between" : "center"}
              width="100%"
              p="10px"
              mb={5}
              h="30px"
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                cursor="pointer"
                onClick={() => navigate("/app/dashboard")}
              >
                <Image
                  src="/logo-white.svg"
                  alt="Zolo Logo"
                  width="50px"
                  style={{
                    transition: "display 0.3s ease-in-out",
                    display:
                      (!sidebarOpen && !isHovered) || sidebarOpen
                        ? "block"
                        : "none",
                  }}
                />
                {sidebarOpen && (
                  <Text
                    fontSize="14px"
                    fontWeight="extrabold"
                    color="white"
                    ml="4px"
                  >
                    Single Touch
                  </Text>
                )}
              </Flex>
              <Box>
                <Box
                  style={{
                    transition: "opacity 0.3s ease-in-out",
                    opacity: isHovered ? 1 : 0,
                  }}
                >
                  {isHovered && (
                    <Tooltip
                      label={sidebarOpen ? "Collapse" : "Expand"}
                      placement="right"
                    >
                      <Flex
                        cursor="pointer"
                        onClick={toggleSidebar}
                        borderRadius="6px"
                        p="2px"
                        _hover={{
                          bg: "brand.green.100",
                          transition: "background-color 0.3s ease-in-out",
                        }}
                      >
                        {sidebarOpen ? (
                          <Icons.ChevronLeft
                            fill={"white"}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <Icons.ChevronRight
                            fill={"white"}
                            width={20}
                            height={20}
                          />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Flex>
          </Flex>
          <Flex
            mb={sidebarOpen ? "16px" : "26px"}
            flexDir="column"
            alignItems="center"
            gap="6px"
          >
            <Box
              cursor="pointer"
              borderRadius="50%"
              onClick={() => navigate("/app/account")}
              _hover={{
                transition: "box-shadow 0.2s ease-in-out",
                boxShadow: "0 0 10px #457662",
              }}
            >
              <AvatarCustom
                src={photoUri}
                widthxheight={sidebarOpen ? "80px" : "40px"}
              />
            </Box>
            <Box
              hidden={!sidebarOpen}
              color="white"
              fontSize="16px"
              fontWeight="bold"
            >
              {ellipsesText(companyName, 20)}
            </Box>
          </Flex>
          <Center>
            <Flex
              cursor="pointer"
              hidden={!sidebarOpen}
              flexDir="row"
              gap="14px"
              mb="22px"
              fontWeight="700"
              fontSize="14px"
              color="white"
            >
              {rebateRequired && (
                <Tooltip label="Credits" placement="bottom">
                  <Flex alignItems="center" borderRadius="4px">
                    <MonetizationOnIcon
                      style={{
                        fontSize: "18px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                    {credits ?? 0}
                  </Flex>
                </Tooltip>
              )}
              <Tooltip label="Emissions saved (month)" placement="bottom">
                <Flex alignItems="center" borderRadius="4px">
                  <Box style={{ fontSize: "18px", marginRight: "4px" }}>
                    <CompostIconSvg fill="white" height={18} width={18} />
                  </Box>
                  {monthEmission ?? 0}
                </Flex>
              </Tooltip>
            </Flex>
          </Center>

          {(isHeadquarters
            ? links
            : links.filter((x) => x.route !== "branch")
          ).map((link, i) => {
            return (
              <LinkStyled
                sidebarOpen={sidebarOpen}
                collapsedTooltip={link.name}
                active={window.location.pathname.startsWith(
                  `/app/${link.route}`
                )}
                key={i}
                onClick={() => navigate(`/app/${link.route}`)}
              >
                <Flex flexDirection="row" gap={1} alignItems={"center"}>
                  <Icon as={link.icon}></Icon>
                  {sidebarOpen && <Text fontSize={"15px"}>{link.name}</Text>}
                </Flex>
              </LinkStyled>
            );
          })}
        </Box>
        <Box>
          <LinkStyled
            sidebarOpen={sidebarOpen}
            collapsedTooltip="Settings"
            active={window.location.pathname.startsWith(`/app/account`)}
            onClick={() => navigate(`/app/account`)}
          >
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              <Icon as={AccountCircleIcon}></Icon>
              {sidebarOpen && (
                <Text fontSize={"15px"}>
                  {isHeadquarters ? "HQ" : "Branch"} Settings
                </Text>
              )}
            </Flex>
          </LinkStyled>

          <LinkStyled
            sidebarOpen={sidebarOpen}
            collapsedTooltip="Logout"
            onClick={logout}
          >
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              <Icon as={LogoutIcon}></Icon>
              {sidebarOpen && <Text fontSize={"15px"}>Logout</Text>}
            </Flex>
          </LinkStyled>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick, sidebarOpen, collapsedTooltip } =
    props;

  const ActionButton = () => {
    return (
      <Button
        onClick={onClick}
        style={{ fontFamily: "zolo-text" }}
        justifyContent={sidebarOpen ? "flex-start" : "center"}
        flexDirection={"row"}
        alignItems="center"
        bg="transparent"
        color={active ? "brand.yellow.200" : "white"}
        borderLeftColor={active ? "brand.yellow.200" : "brand.green.200"}
        mb={{
          lg: "10px",
        }}
        mx={{
          xl: "auto",
        }}
        ps={{
          sm: "10px",
          xl: "12px",
        }}
        _hover={{
          bg: "brand.green.100",
          transition: "background-color 0.3s ease-in-out",
        }}
        py="12px"
        w="100%"
      >
        {children}
      </Button>
    );
  };

  if (sidebarOpen) {
    return <ActionButton />;
  }

  return (
    <Tooltip label={collapsedTooltip} placement="right" ml="5px" p="10px" borderRadius="6px" bg="brand.green.200" color="white">
        {ActionButton()}
      </Tooltip>
  );
};
