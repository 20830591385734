import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Box, Divider, Flex, Text, Image } from "@chakra-ui/react";
import { convertIsoTime } from "../../../util/date.util";
import { AvatarCustom } from "../../form/avatar.custom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import { useContext, useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ellipsesText } from "../../../util/string.util";
import Tooltip from "../../shared/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AppContext } from "../../../App";

export const JobDetailContent = ({ job, inventory }) => {
  const { currencySymbol } = useContext(AppContext);
  const companyState = useSelector((state: RootState) => state.company);

  return (
    <>
      <Flex
        pos="relative"
        flexDir="row"
        gap="4px"
        justifyContent="space-between"
      >
        <Flex fontWeight="500" color="gray" flexDir="column" gap="8px">
          <Flex flexDir="row" gap="4px">
            <BusinessIcon />
            <Text
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                window.open(
                  `/app/company/${job.companyId}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              {job.companyName}
            </Text>
          </Flex>
          <Flex flexDir="row" gap="4px">
            <LocationOnOutlinedIcon />
            {job.companyAddressName}
          </Flex>
        </Flex>

        <Flex
          pos="absolute"
          top="-40px"
          right="-16px"
          flexDir="row"
          gap="28px"
          w="370px"
          py="10px"
          px="18px"
          borderLeftRadius="12px"
          boxShadow="md"
          bg="#6fa790"
        >
          <DashboardCard
            type="count"
            value={inventory.totalItems}
            text="items"
            tooltip="Total items recovered"
          />

          <DashboardCard
            type="weight"
            value={`${job.weight}kg`}
            text="weight"
            tooltip="e-waste diverted from landfill"
          />
          {companyState.rebateRequired && (
            <DashboardCard
              type="care"
              value={`${currencySymbol}${inventory.data
                .map((x) => x.rebate)
                .reduce((a, b) => a + b, 0)
                .toLocaleString("en-US")}`}
              text="rebate"
              tooltip="buy-back credits"
            />
          )}
        </Flex>
      </Flex>

      <Divider my={4} />

      <Flex flexDir="row" justifyContent="space-between">
        <Flex flexDir="column" gap="16px" maxW="500px">
          <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Collection Date"
              value={convertIsoTime(job.startDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            <CardDetail
              label="Created Date"
              value={convertIsoTime(job.createdDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            {/* <PhotoCard src={job.contactPhotoUri} title="Main Contact">
              {job.contactEmail ?? "-"}
            </PhotoCard> */}
          </Flex>

          {/* <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Collection Date"
              value={convertIsoTime(job.startDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            {job.assignedAdminPhotoUri && (
              <PhotoCard src={job.assignedAdminPhotoUri} title="ZOLO Champion">
                {job.assignedAdminEmail}
              </PhotoCard>
            )}
          </Flex> */}

          {!!job.notes && (
            <Box mb="16px">
              <CardDetail
                label="Notes"
                value={job.notes}
                icon={CommentOutlinedIcon}
              />
            </Box>
          )}
        </Flex>

        <AttachmentSection job={job} />
      </Flex>
    </>
  );
};

const CardDetail = ({ label, value, icon = undefined, ...props }) => {
  return (
    <Flex fontSize="14px" flexDir="column" gap="6px" {...props}>
      <Box fontWeight="500" color="gray">
        {label}
      </Box>
      <Box>{value}</Box>
    </Flex>
  );
};

const PhotoCard = ({ src, title, children }) => {
  return (
    <Flex flexDir="row" gap="12px" alignItems="center">
      <AvatarCustom border="3px solid white" src={src} widthxheight="50px" />
      <Box fontSize="14px">
        <Box fontWeight="500" color="gray">
          {title}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

const AttachmentSection = ({ job }) => {
  const [attachments, setAttachments] = useState(job.attachments ?? []);

  useEffect(() => {
    setAttachments(job.attachments);
  }, [job.attachments]);

  return (
    <>
      <Flex
        flexDir="column"
        textAlign="right"
        alignItems="flex-end"
        maxW="400px"
      >
        <Flex fontWeight="500" fontSize="14px" mb={2}>
          Attachments - ({attachments?.length ?? 0}){" "}
          <AttachFileIcon style={{ width: "16px" }} />
        </Flex>

        {attachments?.map((attachment, index) => (
          <Flex
            key={index}
            cursor="pointer"
            fontWeight="500"
            alignItems="center"
            gap={1}
          >
            <Box
              fontSize="10px"
              fontWeight="500"
              color="white"
              bg="gray"
              borderRadius="4px"
              px={2}
              h="fit-content"
            >
              {attachment.mediaContext}
            </Box>

            <Box
              textDecor="underline"
              color="blue"
              fontSize="14px"
              w="160px"
              onClick={() => window.open(attachment.fileUri, "_blank")}
            >
              {ellipsesText(attachment.name, 22)}
            </Box>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

const DashboardCard = ({
  type,
  value,
  text,
  iconStyle = undefined,
  tooltip = undefined,
  ...props
}) => {
  const Icon = () => {
    switch (type) {
      case "weight":
        return "battery.svg";
      case "emission":
        return "footprint.svg";
      case "count":
        return "earth.svg";
      case "pickup":
        return "truck.svg";
      default:
        return "care.svg";
    }
  };

  return (
    <Tooltip label={tooltip} placement="top-end">
      <Flex
        pos="relative"
        flexDir="row"
        gap="4px"
        alignItems="center"
        color="white"
        {...props}
      >
        <Box style={iconStyle}>
          <Image src={`/assets/dashboard/icons/${Icon()}`} w="40px" />
        </Box>

        <Flex flexDir="column">
          <Box fontFamily="zolo-header" fontSize="16px" fontWeight="bold">
            {value}
          </Box>
          <Box fontWeight="400" fontSize="14px">
            {text}
          </Box>
        </Flex>
      </Flex>
    </Tooltip>
  );
};
